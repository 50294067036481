.email-input-container {
    margin-bottom: 8px;
}

.email-list-container {
    max-height: 200px;
    overflow-y: auto;
    margin-top: 8px !important;
}

.email-list-item {
    display: flex;
    align-items: center;
    padding: 8px !important;
}

.email-error-message {
    color: #db2828;
    font-size: 0.9em;
    margin-bottom: 8px;
}

.email-list-container::-webkit-scrollbar {
    width: 8px;
}

.email-list-container::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
}

.email-list-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.email-list-container::-webkit-scrollbar-thumb:hover {
    background: #555;
} 